<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../assets/images/logo/logo-symbol-light.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
      v-bind:style='{ backgroundImage: "url(" + photo + ")" }'
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="blog-single-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient">
                {{ title }}
              </h2>
              <ul class="blog-meta d-flex justify-center align-center">
                <li class="d-flex" v-if="meta.published">
                  <span v-html="iconSvg(meta.icon)"></span>{{ formatCreatedAt(meta.published) }}
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--110 pb--70 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner" v-html="content">
                {{ content }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import axios from 'axios';
  import feather from "feather-icons";

  export default {
    components: {
      Header,
      Footer
    },
      data() {
          return {
              title: null,
              content: null,
              photo: null,
              quote: null,
              meta:  {
                  icon: "clock",
                  published: null,
              },
          };
      },
      methods: {
          iconSvg(icon) {
              return feather.icons[icon].toSvg();
          },
          formatCreatedAt(createdAt) {
              return (new Date(parseInt(createdAt) * 1000)).toLocaleDateString("pl-PL");
          }
      },
      mounted() {
          // const slug = 'warsztat-z-goranem-morkebergiem-w-lublinie';
          const path = this.$route.path.split('/') ;
          const slug = path[2];
          console.log("SLUG", slug);

          axios({
              url: process.env.VUE_APP_API_ENDPOINT,
              method: 'post',
              data: {
                  query: `
                    query getNews {
                        article(slug: "` + slug + `") {
                            title,
                            photo,
                            body,
                            createdAt
                        }
                    }
                `
              }
          })
          .then(response => {
              if (response.data.data.article) {
                  this.title = response.data.data.article.title;
                  this.content = response.data.data.article.body;
                  this.photo = response.data.data.article.photo;
                  this.meta.published = response.data.data.article.createdAt;
              }
          })
      }
  };
</script>
